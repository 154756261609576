import { adminClient } from "@api/admin/AdminClient";
import InternalLink from "@components/InternalLink";
import TableComponent, { Header } from "@components/TableComponent";
import { Box, Button, FormControl, FormControlLabel, FormGroup, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { AdminMessageThreadsGetMessageThreadResponse } from "@reshopper/admin-client";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";

export default function MessageThreadComponent() {
	const [searchString, setSearchString] = useState<string>();
	const [updateTable, setUpdateTable] = useState(false);
	const [shouldSearchLastTwoWeeks, setShouldSearchLastTwoWeeks] = useState<boolean>(true);
	const [shouldSortByScore, setShouldSortByScore] = useState<boolean>(true);



	function renderMessageThreadRow(messageThread: AdminMessageThreadsGetMessageThreadResponse) {
		return <>
			<TableCell >
				<Table size="small" >
					<TableHead >
						<TableRow style={{ backgroundColor: "lightblue" }}>
							<TableCell align="left">{messageThread.sellerPublicName + " (seller)" ?? "Unknown seller"}</TableCell>
							<TableCell style={{ maxWidth: 180 }} align="right"></TableCell>
							<TableCell style={{ width: 180 }} align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{messageThread.messages.map(message =>
							<TableRow key={message.id} >
								<TableCell align="left">{message.text}</TableCell>
								<TableCell style={{ maxWidth: 180 }} align="right">
									{message.userId && <InternalLink href={`/admin/users/details/?id=${encodeURIComponent(message.userId)}`}>
										<>
											{message.authorPublicName}
											{message.shadowblockedAtUtc && <PersonOutlineIcon />}
											{message.ignoreAutomaticShadowblockingFromUtc && <DoneOutlineIcon color="primary" />}
										</>
									</InternalLink>}
								</TableCell>
								<TableCell style={{ width: 180 }} align="right">{toDanishDateFormat(message.createdUtc, "PPp:ss")}</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableCell>
			<TableCell>
				{messageThread.itemId && <InternalLink href={`/admin/items/details/?id=${encodeURIComponent(messageThread.itemId)}`}>
					Item
				</InternalLink>}
				{messageThread.transactionId && <InternalLink href={`/admin/transactions/details/?id=${encodeURIComponent(messageThread.transactionId)}`}>
					Transaction
				</InternalLink>}
			</TableCell>
		</>
	}

	const messageThreadHeaders: Array<Header<AdminMessageThreadsGetMessageThreadResponse>> = [
		{
			databaseSortingKey: null
		},
		{
			databaseSortingKey: null
		}
	];

	const onSubmit = async (e: any) => {
		e.preventDefault();
		setUpdateTable((oldUpdateTableValue) => !oldUpdateTableValue);
	}

	return <Box>
		<Box
			display="flex"
			flexDirection="row"
			alignItems="center"
			flexWrap="nowrap"
			justifyContent="flex-start">

			<FormControl>
				<TextField
					style={{ marginRight: 8 }}
					label={("Message search string")}
					type="text"
					value={searchString}
					onChange={e => setSearchString(e.target.value)}
					margin="none"
					size="small"
				/>
			</FormControl>
			<FormGroup>
				<FormControlLabel control=
					{<Switch
						onClick={() => setShouldSearchLastTwoWeeks(!shouldSearchLastTwoWeeks)}
						color="primary"
						name="lastTwoWeeks"
						aria-label="Should search last two weeks"
						checked={shouldSearchLastTwoWeeks}
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>}
					label="Should search last two weeks"
					labelPlacement="bottom" />
			</FormGroup>
			<FormGroup>
				<FormControlLabel control=
					{<Switch
						onClick={() => setShouldSortByScore(!shouldSortByScore)}
						color="primary"
						name="sortByScore"
						aria-label="Should sort by score"
						checked={shouldSortByScore}
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>}
					label="Should sort by score"
					labelPlacement="bottom" />
			</FormGroup>
			<Button
				style={{ marginRight: 8 }}
				color="primary"
				variant="contained"
				onClick={onSubmit}
			>
				Submit
			</Button>
		</Box>
		<TableComponent
			call={async (options) =>
				await adminClient().adminMessageThreadsGet({
					...options,
					searchString: searchString,
					onlySearchLastTwoWeeks: shouldSearchLastTwoWeeks,
					shouldSortBySearch: shouldSortByScore
				})}
			isSelectable={false}
			sortDirection="desc"
			size={10}
			headers={messageThreadHeaders}
			dependencies={[updateTable]}
			renderRow={messageThread => renderMessageThreadRow(messageThread)}
		/>
	</Box>
}