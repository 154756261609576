import { withAdminMenuLayout } from "@components/admin/Decorators"
import MessageThreadComponent from "@components/admin/message-threads/MessageThreadComponent"
import PageTitle from "@components/admin/PageTitle"
import Box from "@material-ui/core/Box/Box"

export default withAdminMenuLayout(function RecentMessageThreadsPageComponent() {
	return <>
		<PageTitle title="Recent message threads" />
		<Box>
			<MessageThreadComponent />
		</Box>
	</>
})
